import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'

import Typography from '../../Common/Typography'
import { Button } from '../../Common/Button'
import ArrowLinkButton from '../../../svg/arrow-link-button'

import * as s from './ModuleSectionItem2.module.scss'

const authors = [
  'Dmitri D.',
  'Alexander P.',
  'Roman S.',
  'Andrew S.',
  'Michel K.',
  'Antony S.',
  'Alexander K.',
  'Alexander E.',
  'Sergey Kazantsev',
  'author_10',
  'author_11',
  'author_12',
  'author_13',
  'author_14',
  'author_15',
  'author_16',
  'author_17',
  'author_18',
  'author_19',
  'author_20',
  'author_21',
  'author_22',
  'author_23',
  'author_24',
] as const

const variants = {
  h2: 'h2',
  h4: 'h4',
} as const

type Author = typeof authors[number]

interface ModuleSectionItem2Props {
  title: string
  description: string
  variantTitle?: keyof typeof variants
  positionTitle?: 'left' | 'center' | 'right'
  quote?: {
    author: Author
    position: string
    text: string
  }
  btnText?: string
  onClick?: () => void
  className?: string
  columnLeftChildren?: React.ReactNode
  columnLeftClassName?: string
  columnRightChildren?: React.ReactNode
  image?: IGatsbyImageData
  reverseElements?: boolean
}

export const ModuleSectionItem2: React.FC<ModuleSectionItem2Props> = ({
  title,
  variantTitle = 'h4',
  positionTitle = 'left',
  description,
  quote,
  image,
  onClick,
  btnText,
  className,
  reverseElements = false,
  columnLeftChildren,
  columnLeftClassName,
  columnRightChildren,
}) => {
  const images = useStaticQuery(imagesQuery)

  return (
    <div className={cn(s.content, className, reverseElements && s.reverse)}>
      <div className={cn(s.column, s.column_left, columnLeftClassName)}>
        <div className="flex">
          <Typography
            variant={variantTitle}
            onClick={onClick}
            className={cn(
              s.title,
              onClick && s.title_hover,
              positionTitle !== 'left' && s[`title_${positionTitle}`]
            )}
          >
            {title}
          </Typography>
        </div>
        {quote && (
          <div className={s.quote}>
            <div className="flex w-100">
              <div className={s.avatar}>
                <GatsbyImage
                  image={
                    images[`author${authors.indexOf(quote.author) + 1}`]
                      .childImageSharp.gatsbyImageData
                  }
                  alt={quote.position}
                  title={quote.position}
                />
              </div>
              <div className={s.quote_info}>
                <span className={s.position}>{quote.position}</span>
                <p className={s.quote_text}>{quote.text}</p>
              </div>
            </div>
            <p className={cn('is-hidden-desktop', s.quote_text)}>
              {quote.text}
            </p>
          </div>
        )}
        <p className={s.description}>{description}</p>
        {columnLeftChildren}
        {btnText && (
          <Button onClick={onClick} className={cn(s.btn, s.is_desktop)}>
            {btnText}
            <ArrowLinkButton className={s.arrow} />
          </Button>
        )}
      </div>
      <div
        className={cn(
          'flex justify-center',
          reverseElements ? 'mr-4' : 'ml-4',
          s.column
        )}
      >
        {image && (
          <div>
            <GatsbyImage image={image} alt={title} title={title} />
          </div>
        )}
        {columnRightChildren}
      </div>
      {btnText && (
        <Button onClick={onClick} className={cn(s.btn, s.is_mobile)}>
          {btnText}
          <ArrowLinkButton className={s.arrow} />
        </Button>
      )}
    </div>
  )
}

const imagesQuery = graphql`
  query {
    author1: file(relativePath: { eq: "author/Dmitri_D.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author2: file(relativePath: { eq: "author/Alexander_P.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author3: file(relativePath: { eq: "author/Roman_S.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author4: file(relativePath: { eq: "author/Andrew_S.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author5: file(relativePath: { eq: "author/Michel_K.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author6: file(relativePath: { eq: "author/Antony_S.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author7: file(relativePath: { eq: "author/Alexander_K.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author8: file(relativePath: { eq: "author/Alexander_E.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author9: file(relativePath: { eq: "author/author_9.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author10: file(relativePath: { eq: "author/author_10.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author11: file(relativePath: { eq: "author/author_11.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author12: file(relativePath: { eq: "author/author_12.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author13: file(relativePath: { eq: "author/author_13.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author14: file(relativePath: { eq: "author/author_14.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author15: file(relativePath: { eq: "author/author_15.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author16: file(relativePath: { eq: "author/author_16.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author17: file(relativePath: { eq: "author/author_17.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author18: file(relativePath: { eq: "author/author_18.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author19: file(relativePath: { eq: "author/author_19.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author20: file(relativePath: { eq: "author/author_20.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author21: file(relativePath: { eq: "author/author_21.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author22: file(relativePath: { eq: "author/author_22.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author23: file(relativePath: { eq: "author/author_23.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
    author24: file(relativePath: { eq: "author/author_24.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80, placeholder: BLURRED)
      }
    }
  }
`
