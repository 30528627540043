import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import { DescriptionBlock2 } from '../../components/description-block'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import List from '../../components/Common/List'
import RequestDemo from '../../components/RequestDemo'
import ContactForm from '../../components/contact-form'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './digital-services.module.scss'

const DigitalServices: React.FC = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title="Digital Services"
        description="Measure work accurately with digital methods like laser scanning, drones, and 360° photos. Select the right technique based on task, object features, accuracy, and timing."
        keywords="Precision laser scanning of buildings, Laser scanning for construction sites, Aerial surveying of the construction site, 360 photos on construction sites, Laser scanning, Stockpile measurements, Digital Elevation map, Aerial solutions, Orthophotomosaic, Drones in construction"
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title="SIGNAX Digital Services"
        colorShadow="dark"
      />
      <Section className={s.section_description}>
        <DescriptionBlock2
          title={`Obtain digital data in a quick\n and accurate way`}
          description={`Digital services allow you to confirm the work performed and obtain digital data on\u2011site.\n Laser scanning, drone survey, or 360-degree photo — choose the best method for your tasks, required accuracy, and time.`}
          classNameTitle="mb-4"
        />
      </Section>
      <ModuleSection2>
        <ModuleSectionItem2
          title="Laser scanning"
          variantTitle="h2"
          description="SIGNAX professionals can provide laser scanning to ensure the construction scope is accurate."
          image={images.image.childImageSharp.gatsbyImageData}
          btnText="Read more"
          onClick={() => navigate(getPath('/laser-scanning/'))}
          className="p-0"
          columnLeftClassName="flex justify-center"
          columnLeftChildren={
            <List className={s.list}>
              <li>A fast and helpful method of obtaining digital data</li>
              <li>Aerial, ground, or mobile options</li>
              <li>
                {`The result is a point cloud which can be the base for\u00A0the\u00A0digital\u00A0twin of an object`}
              </li>
            </List>
          }
        />
        <ModuleSectionItem2
          title={`Drone’s (UAV) capture for\u00A0site control`}
          description={`SIGNAX capture services are an intelligent way to control situations and processes on-site.`}
          variantTitle="h2"
          image={images.image2.childImageSharp.gatsbyImageData}
          btnText="Read more"
          onClick={() => navigate(getPath('/aerial-drone-survey/'))}
          className={s.module_item}
          columnLeftChildren={
            <List className={s.list}>
              <li>
                {`A fast and effective method of construction work progress monitoring`}
              </li>
              <li>
                {`Allows objective data from the construction site to\u00A0all\u00A0project\u00A0stakeholders remotely`}
              </li>
              <li>
                {`Integrate results into smart data cards® on your smartphone`}
              </li>
            </List>
          }
          reverseElements
        />
        <ModuleSectionItem2
          title="Photo360"
          description={`See your site from anywhere, any time. SIGNAX can help control\u00A0all\u00A0kinds of indoor work.`}
          variantTitle="h2"
          image={images.image3.childImageSharp.gatsbyImageData}
          btnText="Read more"
          onClick={() => navigate(getPath('/remote-inspection/'))}
          className="p-0"
          columnLeftChildren={
            <List className={s.list}>
              <li>
                {`Capture the construction site and track day-to-day progress by taking periodic 360-degree photos`}
              </li>
              <li>
                {`This technology provides visual information about the\u00A0construction to the project team`}
              </li>
              <li>
                {`Panoramic photo capturing ensures work is done and can\u00A0be\u00A0approved for payment`}
              </li>
            </List>
          }
        />
      </ModuleSection2>
      <RequestDemo
        title={t('Know more')}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default DigitalServices

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "digital-services/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image: file(relativePath: { eq: "digital-services/image-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "digital-services/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 580, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "digital-services/image-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
  }
`
